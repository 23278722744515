<script>
    var firstTabEl = document.querySelector('#myTab li:last-child a')
    var firstTab = new bootstrap.Tab(firstTabEl)
  
    firstTab.show()
  </script>
<div class="container-fluid" >
    <div class="row">
        <div class="col-12" >
            <!-- <img src="../../assets/funrun2023-website-min.jpg" style="margin: 82px auto; width: 100%; min-height: 200px;"/>
             -->
             <img src="../../assets/2025/funrun-header.jpg" style="margin: 82px auto; width: 100%; min-height: 200px;"/>
        </div>
    </div>
    <div class="row" style="margin-top: -60px;">
        <div class="col-12">
            <ul class="nav nav-pills" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                  <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#guidelines" type="button" role="tab" aria-controls="guidelines" aria-selected="true"><b>GUIDELINES</b></button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link" id="faq-tab" data-bs-toggle="tab" data-bs-target="#faq" type="button" role="tab" aria-controls="faq" aria-selected="false"><b>FAQ</b></button>
                </li>
                <!-- <li class="nav-item" role="presentation">
                  <button class="nav-link" id="messages-tab" data-bs-toggle="tab" data-bs-target="#messages" type="button" role="tab" aria-controls="messages" aria-selected="false">Messages</button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link" id="settings-tab" data-bs-toggle="tab" data-bs-target="#settings" type="button" role="tab" aria-controls="settings" aria-selected="false">Settings</button>
                </li> -->
            </ul>
              
            <div class="tab-content">
                <div class="tab-pane active" id="guidelines" role="tabpanel" aria-labelledby="guidelines-tab">
                    <br>
                    <h4 class="header text-center">GENERAL RULES AND MECHANICS</h4><hr>
                    <div class="row">
                        <div class="col-12">
                            <h5 class="header">AGE LIMIT</h5>
                            <div style="padding-left: 10px">
                                <p><i class="fas fa-angle-right"></i> Race is open to participants 3 to 75 years old.</p>
                                <p><i class="fas fa-angle-right"></i> For participants who are 3 to 12 years old, they must be accompanied by a parent or a guardian, who also registered for the event, from start to finish.</p>
                                <p><i class="fas fa-angle-right"></i> Participants who are 13 to 75 years old can join any distance provided they comply with the following:</p>
                                <p style="padding-left: 20px;">-	All participants agree to the terms and conditions of the race.</p>
                                <p style="padding-left: 20px;">-	All minors (below 18) must have the consent of their parents/guardians. The latter should sign the consent form.</p>
                            </div>
                            <hr>
                            <h5 class="header">REGISTRATION FORM</h5>
                            <p style="font-weight: bold;">Online:</p>
                            <p>You can click this link to register online: <a href="javascript:void()" (click)="goToGoogleForm()">Registration Form</a>.<br> For your registration to be valid, you need to submit all pertinent attachments including proof of payment.</p>
                            <p style="font-weight: bold;">In-Store:</p>
                            <p>All participants must fill out the registration form which can be downloaded by clicking on this link: <a 
                                href="javascript:void(0)" 
                                style="cursor:pointer"
                                (click)='downloadPdf("../../assets/regform.pdf","Wellness-On-the-Move-Registration")'>
                                PDF Registration Form
                                </a>. You can also ask for a printed copy of the registration form from any of our staff. 
                                After filling out all needed information, submit the form, along with all the requirements needed, at the St. Joseph Drug branch near you. Once assessed by our store personnel and payment has been made, you will be given a claim stub which you will use to claim your race kit.
                            </p>
                            <hr>
                            <h5 class="header">REQUIREMENTS</h5>
                            <p>1.	Valid ID</p>
                            <p>2.	SJ Greencard or student ID, if applicable</p>
                            <hr>
                            <h5 class="header">FEES</h5>
                            <p>
                                <img src="../../assets/2025/reg-fee.png" style="max-width: 800px; width:100%; max-height: 300px;">
                            </p>
                            <hr>
                            <h5 class="header">RACE SCHEDULE</h5>
                            <p><img class="col-1" src="../../assets/2025/race-schedule.png" style="max-width: 800px; width:100%; max-height: 300px;"></p>
                            <hr>
                            <h5 class="header">RACE KITS</h5>
                            <p>For the schedule of race kit claiming, please refer below. You will be notified via SMS and/or email once race kits are ready. </p>
                            <p><img src="../../assets/2025/race-kit-claiming.png" style="max-width: 800px; width:100%; max-height: 300px;"></p>
                            <div class="left d-flex flex-wrap align-items-center gap-2">
                                <div><img src="../../assets/2025/race-kit-image.jpg" style="width: 100% !important; max-width: 800px;"></div>
                                
                            </div>
                            <br>
                            <div><img src="../../assets/2025/race-entitle.jpg" style="width: 100% !important; max-width: 800px;"></div>
                            <br><hr>
                            <!--
                            <h5 class="header">ROUTES</h5>
                              <p><img src="../../assets/2024-route-min.png" style="max-width: 800px; width:100%;"></p>
                            <hr>-->
                            <h5 class="header">BAGGAGE COUNTERS AND COMFORT ROOMS</h5>
                            <p>Baggage counters will be provided, but huge bags/luggage are discouraged. Baggage claim stub will be included in the race bib.<br> 
                                <!-- There will be portable toilets in the venue which can be used by the participants. -->
                            </p>
                            <hr>
                            <h5 class="header">BOOTHS</h5>
                            <p>Supplier booths can be found in the event area where participants can get free items. Medical services will also be offered on-site. </p>
                            <hr>
                            <!-- <h5 class="header">SELLING AREA</h5>
                            <p>There will be onsite selling of medicines. A pharmacist will also be available for counseling.</p>
                            <hr> -->
                            <h5 class="header">RAFFLE</h5>
                            <p>Raffle prizes will be up for grabs for the participants during the event. </p>
            
                        </div>
                    </div>
                </div>
                <div class="tab-pane" id="faq" role="tabpanel" aria-labelledby="faq-tab">
                    <br>
                    <div class="accordion" id="accordionPanel">
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                            <button class="accordion-button text-top" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                              <div class="text-top" style="padding-right: 10px"><strong>1.</strong></div>
                              <div><strong>What is included in the entry fee for the race?</strong></div>
                            </button>
                          </h2>
                          <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
                            <div class="accordion-body" style="padding-left: 50px">
                              <p>The entry fee includes a race kit for all registered runners, consisting of a race shirt, race bib with RFID, PVC pouch, sunglasses, glow bracelet, holi powder, and a finisher medal (to be given at the finish line). The 8k registrants’ race kit will also include one squeeze bottle and they will be given a finisher shirt once they finish the run.</p>
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                              <div class="text-top" style="padding-right: 10px"><strong>2.</strong></div>
                              <div><strong>When is the deadline for registration?</strong></div>
                            </button>
                          </h2>
                          <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo">
                            <div class="accordion-body" style="padding-left: 50px">
                              <p>The registration period will end on April 9, 2025. Be sure to register before the deadline to secure your slot.</p>
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="panelsStayOpen-headingThree">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                              <div class="text-top" style="padding-right: 10px"><strong>3.</strong></div>
                              <div><strong>Can I use someone else's SJ Greencard to receive the registration fee discount?</strong></div>
                            </button>
                          </h2>
                          <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
                            <div class="accordion-body" style="padding-left: 50px">
                              <p><strong>No,</strong> only the SJ Greencard holder can avail of the discount. Each SJ Greencard membership is only entitled to one registration discount and cannot be transferred to another person.</p>
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="panelsStayOpen-heading4">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse4" aria-expanded="false" aria-controls="panelsStayOpen-collapse4">
                              <div class="text-top" style="padding-right: 10px"><strong>4.</strong></div>
                              <div><strong>What happens if I lost my SJ Greencard? Can I still avail of the discounted rate?</strong></div>
                            </button>
                          </h2>
                          <div id="panelsStayOpen-collapse4" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-heading4">
                            <div class="accordion-body" style="padding-left: 50px">
                              <p><strong>No,</strong> you need to present your SJ Greencard to enjoy this member-only privilege. If you lose your SJ Greencard, unfortunately, you cannot avail of the discount.</p>
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="panelsStayOpen-heading5">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse5" aria-expanded="false" aria-controls="panelsStayOpen-collapse5">
                              <div class="text-top" style="padding-right: 10px"><strong>5.</strong></div>
                              <div><strong>Is online registration and payment available?</strong></div>
                            </button>
                          </h2>
                          <div id="panelsStayOpen-collapse5" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-heading5">
                            <div class="accordion-body" style="padding-left: 50px">
                              <p><strong>Yes.</strong> You can pay via GCash or bank deposit when you register online at <a target="_blank" href="bit.ly/wellnessonthemove">bit.ly/wellnessonthemove</a>. You will receive a confirmation email once your registration has been confirmed.</p>
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="panelsStayOpen-heading6">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse6" aria-expanded="false" aria-controls="panelsStayOpen-collapse6">
                              <div class="text-top" style="padding-right: 10px"><strong>6.</strong></div>
                              <div><strong>Are there age limits for the races in the Wellness on the Move event?</strong></div>
                            </button>
                          </h2>
                          <div id="panelsStayOpen-collapse6" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-heading6">
                            <div class="accordion-body" style="padding-left: 50px">
                              <p><strong>Yes,</strong> race is open to participants 3 to 75 years old. Certain conditions will apply. Please check our general rules and conditions.</p>
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="panelsStayOpen-heading7">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse7" aria-expanded="false" aria-controls="panelsStayOpen-collapse7">
                              <div class="text-top" style="padding-right: 10px"><strong>7.</strong></div>
                              <div><strong>Where can I obtain the registration form for the Wellness on the Move event?</strong></div>
                            </button>
                          </h2>
                          <div id="panelsStayOpen-collapse7" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-heading7">
                            <div class="accordion-body" style="padding-left: 50px">
                              <p>The registration form can be downloaded online. Link is indicated in the caption. Additionally, printed copies can be requested at St. Joseph Drug stores in North Luzon.</p>
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="panelsStayOpen-heading8">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse8" aria-expanded="false" aria-controls="panelsStayOpen-collapse8">
                              <div class="text-top" style="padding-right: 10px"><strong>8.</strong></div>
                              <div><strong>Is it possible to withdraw from the Wellness on the Move event?</strong></div>
                            </button>
                          </h2>
                          <div id="panelsStayOpen-collapse8" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-heading8">
                            <div class="accordion-body" style="padding-left: 50px">
                              <p><strong>Yes, </strong>participants can withdraw from the event, but please note that entry fees are non-refundable and non-transferable.</p>
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="panelsStayOpen-heading9">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse9" aria-expanded="false" aria-controls="panelsStayOpen-collapse9">
                              <div class="text-top" style="padding-right: 10px"><strong>9.</strong></div>
                              <div><strong>What documents do I need to bring when registering for the Wellness on the Move event?</strong></div>
                            </button>
                          </h2>
                          <div id="panelsStayOpen-collapse9" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-heading9">
                            <div class="accordion-body" style="padding-left: 50px">
                              <p>Participants need to bring a government-issued ID. If you do not have a government-issued ID, a birth certificate and/or school ID may be required, depending on the circumstances. </p>
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="panelsStayOpen-heading10">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse10" aria-expanded="false" aria-controls="panelsStayOpen-collapse10">
                              <div class="text-top" style="padding-right: 10px"><strong>10.</strong></div>
                              <div><strong>Are there changing facilities available at the event venue?</strong></div>
                            </button>
                          </h2>
                          <div id="panelsStayOpen-collapse10" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-heading10">
                            <div class="accordion-body" style="padding-left: 50px">
                              <p><strong>Yes, </strong>there are portable toilets available at the event venue. </p>
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="panelsStayOpen-heading11">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse11" aria-expanded="false" aria-controls="panelsStayOpen-collapse11">
                              <div class="text-top" style="padding-right: 10px"><strong>11.</strong></div>
                              <div><strong>Will there be a safe place to leave bags at the event?</strong></div>
                            </button>
                          </h2>
                          <div id="panelsStayOpen-collapse11" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-heading11">
                            <div class="accordion-body" style="padding-left: 50px">
                              <p><strong>Yes, </strong>bag drop facilities will be provided at the event. </p>
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="panelsStayOpen-heading12">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse12" aria-expanded="false" aria-controls="panelsStayOpen-collapse12">
                              <div class="text-top" style="padding-right: 10px"><strong>12.</strong></div>
                              <div><strong>What will happen if it rains on the day of the event?</strong></div>
                            </button>
                          </h2>
                          <div id="panelsStayOpen-collapse12" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-heading12">
                            <div class="accordion-body" style="padding-left: 50px">
                              <p>The event will continue as planned, but if there are adverse weather conditions, the start times of the races may be delayed until it is considered safe to run.</p>
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="panelsStayOpen-heading13">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse13" aria-expanded="false" aria-controls="panelsStayOpen-collapse13">
                              <div class="text-top" style="padding-right: 10px"><strong>13.</strong></div>
                              <div><strong>Is it possible to transfer my registration to another runner?</strong></div>
                            </button>
                          </h2>
                          <div id="panelsStayOpen-collapse13" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-heading13">
                            <div class="accordion-body" style="padding-left: 50px">
                              <p><strong>No, </strong>it is not possible to transfer your registration to another runner.</p>
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="panelsStayOpen-heading14">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse14" aria-expanded="false" aria-controls="panelsStayOpen-collapse14">
                              <div class="text-top" style="padding-right: 10px"><strong>14.</strong></div>
                              <div><strong>How will my record time be tracked?</strong></div>
                            </button>
                          </h2>
                          <div id="panelsStayOpen-collapse14" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-heading14">
                            <div class="accordion-body" style="padding-left: 50px">
                              <p>Issued race bibs have RFID tags. RFID hardware is setup at the finish line in order to read the RFID tagged participants. Participant’s race time can be viewed online. S/he can also print a certificate from the site. Link will be provided before the race.</p>
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="panelsStayOpen-heading15">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse15" aria-expanded="false" aria-controls="panelsStayOpen-collapse15">
                              <div class="text-top" style="padding-right: 10px"><strong>15.</strong></div>
                              <div><strong>Can I still receive my finisher medal and/or finisher shirt if I do not finish the race?</strong></div>
                            </button>
                          </h2>
                          <div id="panelsStayOpen-collapse15" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-heading15">
                            <div class="accordion-body" style="padding-left: 50px">
                              <p><strong>No, </strong>finisher medals and shirts will only be issued to participants who have completed the race.</p>
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="panelsStayOpen-heading16">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse16" aria-expanded="false" aria-controls="panelsStayOpen-collapse16">
                              <div class="text-top" style="padding-right: 10px"><strong>16.</strong></div>
                              <div><strong>Can I bring my pets to the event venue?</strong></div>
                            </button>
                          </h2>
                          <div id="panelsStayOpen-collapse16" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-heading16">
                            <div class="accordion-body" style="padding-left: 50px">
                              <p><strong>Yes, </strong>pets are allowed at the venue as long as they are wearing diapers. However, they are not allowed on the race track. Moreover, all pet owners are expected to practice responsible pet ownership.</p>
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="panelsStayOpen-heading17">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse17" aria-expanded="false" aria-controls="panelsStayOpen-collapse17">
                              <div class="text-top" style="padding-right: 10px"><strong>17.</strong></div>
                              <div><strong>Will snacks be provided at the event?</strong></div>
                            </button>
                          </h2>
                          <div id="panelsStayOpen-collapse17" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-heading17">
                            <div class="accordion-body" style="padding-left: 50px">
                              <p><strong>Yes, </strong>snacks and drinks will be available at designated areas in the venue.</p>
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="panelsStayOpen-heading18">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse18" aria-expanded="false" aria-controls="panelsStayOpen-collapse18">
                              <div class="text-top" style="padding-right: 10px"><strong>18.</strong></div>
                              <div><strong>Will there be special awards given out to runners?</strong></div>
                            </button>
                          </h2>
                          <div id="panelsStayOpen-collapse18" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-heading18">
                            <div class="accordion-body" style="padding-left: 50px">
                              <p><strong>Yes, </strong>special awards will be given out to runners. List will be released before the run.</p>
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="panelsStayOpen-heading19">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse19" aria-expanded="false" aria-controls="panelsStayOpen-collapse19">
                              <div class="text-top" style="padding-right: 10px"><strong>19.</strong></div>
                              <div><strong>What other activities can participants and non-runners enjoy during the event?</strong></div>
                            </button>
                          </h2>
                          <div id="panelsStayOpen-collapse19" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-heading19">
                            <div class="accordion-body" style="padding-left: 50px">
                              <p>Participants can enjoy games and other surprises, including a raffle promo. Non-runners can also enjoy discounts from participating sponsors.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      
                </div>
                <!-- <div class="tab-pane" id="messages" role="tabpanel" aria-labelledby="messages-tab">...</div>
                <div class="tab-pane" id="settings" role="tabpanel" aria-labelledby="settings-tab">...</div> -->
            </div>
            <br>
              
        </div>
    </div>
</div>
<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display': displayStyle}">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Registration Closed!</h4>
      </div>
      <div class="modal-body text-center">
          <div class="d-flex justify-content-center">
              <img src="../../assets/sad.png" width="120" />
          </div>
        <h5>Sorry to inform you that registration is already closed. </h5>
        <p>You can visit our facebook page regarding when will be our next event.</p>
  
      </div>
      <div class="modal-footer">
        <button class="btn btn-success" (click)="closeModalStat()">Close</button>
      </div>
    </div>
  </div>
</div>

  
